import React, { useState } from "react";
import images from "../../constants/images";
import "../Navbar/Navbar.css";
import { FaBars, FaTimes } from "react-icons/fa";
import logoadify from '../../assets/images/logo.png'

const Navbar = () => {
    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);
    const close = () => setClick(false)
    return (
        <nav className="navbar">
            <div className="container flex navbar-content">
                <div className="brand-and-toggler flex">
                    <a href="index.html" alt="">
                    <img style={{height:'70px'}} src={logoadify}></img>
                    </a>
                    <button type="button" className="navbar-show-btn text-white" onClick={() => handleClick()}>
                        <FaBars size={26} />
                    </button>
                </div>
                <div className={`navbar-collapse flex flex-center ${click ? "show-navbar" : " "}`}>
                    <button type="button" className="navbar-hide-btn text-white"
                        onClick={() => close()}>
                        <FaTimes size={32} />
                    </button>
                    <ul className="navbar-nav text-upper text-white fw-6 ls-1 fs-20 text-center">
                        <li className="nav-item">
                            <a style={{fontSize: '15px'}} href="#header" className="nav-link">Home</a>
                        </li>
                        <li className="nav-item">
                            <a style={{fontSize: '15px'}} href="#about-us" className="nav-link">About Us</a>
                        </li>
                        <li className="nav-item">
                            <a style={{fontSize: '15px'}} href="#our-services" className="nav-link">Services</a>
                        </li>
                        <li className="nav-item">
                            <a style={{fontSize: '15px'}} href="#contact-us" className="nav-link">Contact Us</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Navbar
