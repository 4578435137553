import React from 'react'
import HeroClasses from '../Hero/Hero.module.css';
import Maskman from '../../assets/images/clientprofilepic1.jpg';
import Maskman1 from '../../assets/images/clientprofilepic2.jpg';
import Maskman3 from '../../assets/images/clientprofilepic3.jpg';

const ServiceCards = () => {
    return (
        <div>

            <div className="deal-container">
                {/* Testimonial Card 1 */}
                <div className="card testimonial-card" data-aos="fade-zoom-in"
                    data-aos-easing="ease-in-back"
                    data-aos-delay="100"
                    data-aos-offset="0">
                    <p className="testimonial-text">
                        Adify's cleaning services transformed my home! Their attention to detail and professionalism are unmatched. I especially appreciated the care they took with our delicate surfaces.
                    </p>
                    <div className="profile-container">
                        <img className="profile-image" src={Maskman1} alt="Sarah L." />
                        <div className="profile-details">
                            <h4 className="profile-name">Sarah L.</h4>
                            <p className="profile-title">Satisfied Customer</p>
                        </div>
                    </div>
                </div>

                {/* Testimonial Card 2 */}
                <div className="card testimonial-card" data-aos="fade-zoom-in"
                    data-aos-easing="ease-in-back"
                    data-aos-delay="300"
                    data-aos-offset="0">
                    <p className="testimonial-text">
                        I've tried various cleaning companies, but Adify stands out. Their prompt service and high-quality results are consistently impressive. My office has never looked better!
                    </p>
                    <div className="profile-container">
                        <img className="profile-image" src={Maskman} alt="John M." />
                        <div className="profile-details">
                            <h4 className="profile-name">John M.</h4>
                            <p className="profile-title">Office Manager</p>
                        </div>
                    </div>
                </div>

                {/* Testimonial Card 3 */}
                <div className="card testimonial-card" data-aos="fade-zoom-in"
                    data-aos-easing="ease-in-back"
                    data-aos-delay="500"
                    data-aos-offset="0">
                    <p className="testimonial-text">
                        Adify exceeded my expectations with their thorough and efficient cleaning services. Their team is friendly and reliable, making them my go-to choice for home cleaning.
                    </p>
                    <div className="profile-container">
                        <img className="profile-image" src={Maskman3} alt="Emily R." />
                        <div className="profile-details">
                            <h4 className="profile-name">Emily R.</h4>
                            <p className="profile-title">Homeowner</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiceCards
