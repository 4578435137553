import React from 'react'
import HeroClasses from '../Hero/Hero.module.css'

const HeadAboutUs = () => {
    return (
        <div>
            <div className={HeroClasses['exclusive-main']} id='about-us' style={{ marginTop: '550px' }}>
                <h1 className={HeroClasses['exclusive-para']}>About Us</h1>
            </div>
        </div>
    )
}

export default HeadAboutUs
