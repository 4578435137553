import React, { useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useInView } from 'react-intersection-observer';
import './CleaningServices.css';
import side1img from '../../assets/images/cleaning-service-3.jpg'

const CleaningServices = () => {
    const [officeCleaningProgress, setOfficeCleaningProgress] = useState(0);
    const [homeCleaningProgress, setHomeCleaningProgress] = useState(0);

    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.6, // 30% of the component needs to be visible
    });

    React.useEffect(() => {
        if (inView) {
            // Animate progress bars when the component is in view
            setOfficeCleaningProgress(35);
            setHomeCleaningProgress(65);
        }
    }, [inView]);


    return (
        <div className="cleaning-services-container" ref={ref}>
            <div className="cleaning-services-image">
                <img src={side1img} alt="Cleaning Products" />
            </div>
            <div className="cleaning-services-content">
                <h3>WE TAKE PRIDE IN CLEAN</h3>
                <h1>Let Adify Handle the Dirty Work</h1>
                <p>
                    Adify is a technology platform offering a helping aid services at home.
                    Customers use our platform to book services such as Helper and Maid
                    services. These services are delivered in the comfort of their home and at a
                    time of their choosing. We promise our customers a high quality,
                    standardised and reliable service experience. To fulfil this promise, we work
                    closely with our hand-picked service partners, enabling them with
                    technology, training, products, tools, financing, insurance and brand,
                    helping them succeed and deliver on this promise.
                    Our Vision: Empower millions of professionals worldwide to deliver services
                    at home like never experienced before. And provide a helping hand to
                    millions of users in an emergency and curtail period of time. “When no one
                    is there, we are here to help “
                </p>
                <p>Adify provides a platform that allows skilled and experienced professionals
                    to connect with users looking for specific services. Once on the platform,
                    our match-making algorithm identifies partners who are closest to the
                    users’ requirements and available at the requested time and date.</p>
                <div className="progress-container">
                    <div className="progress-item">
                        <CircularProgressbar
                            value={officeCleaningProgress}
                            text={`${officeCleaningProgress}%`}
                            styles={buildStyles({
                                pathColor: '#68c8c7',
                                textColor: '#000',
                                trailColor: '#eee',
                            })}
                        />
                        <span>Office cleaning</span>
                    </div>
                    <div className="progress-item">
                        <CircularProgressbar
                            value={homeCleaningProgress}
                            text={`${homeCleaningProgress}%`}
                            styles={buildStyles({
                                pathColor: '#68c8c7',
                                textColor: '#000',
                                trailColor: '#eee',
                            })}
                        />
                        <span>Home cleaning</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CleaningServices;
