import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import './assets/js/script';
import Header from './components/Header/Header';
import WhatWeDo from './components/WhatWeDo/WhatWeDo';
import Features from './components/Features/Features';
import Packages from './components/Packages/Packages';
import Projects from './components/Projects/Projects';
import Team from './components/Team/Team';
import Support from './components/Support/Support';
import Footer from './components/Footer/Footer';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Hero from './components/Hero/Hero';
import Navbar from './components/Navbar/Navbar';
import DealsForYou from './components/Deals/DealsForYou';
import MaidHelperSlider from './components/maidhelperslider/MaidHelperSlider';
import ClientSays from './components/clientsays/ClientSays';
import CleaningServices from './components/cleaningservices/CleaningServices';
import ServiceBookingForm from './components/servicesbooking/ServiceBookingForm';
import ServiceCards from './components/servicecards/ServiceCards';
import HeadAboutUs from './components/headaboutus/HeadAboutUs';
import TermsAndConditions from './components/termsandconditions/TermsAndConditions';
import PrivacyPolicy from './components/privacypolicy/PrivacyPolicy';

AOS.init();

function App() {
  const location = useLocation();
  
  // Define the routes where you don't want to show the Navbar and Footer
  const noHeaderFooterRoutes = ['/terms-and-conditions', '/privacy-policy'];

  return (
    <div className="App">
      {/* Conditionally render Navbar */}
      {!noHeaderFooterRoutes.includes(location.pathname) && <Navbar />}
      
      <Routes>
        <Route path="/" element={
          <>
            <MaidHelperSlider />
            <HeadAboutUs />
            <CleaningServices />
            <ServiceCards />
            <ClientSays />
            <DealsForYou />
            <ServiceBookingForm />
          </>
        } />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
      
      {/* Conditionally render Footer */}
      {!noHeaderFooterRoutes.includes(location.pathname) && <Footer />}
    </div>
  );
}

export default App;
