// DealsForYou.js
import React from 'react';
import './DealsForYou.css';
import HeroClasses from '../Hero/Hero.module.css';
import bitmap from '../../assets/images/service-card1.jpg';
import bitmapsoap from '../../assets/images/service-card2.jpg';
import earbud from '../../assets/images/service-card3.jpg';
import bottle from '../../assets/images/service-card4.jpg';
import servicecard5 from '../../assets/images/service-card5.jpg';
import servicecard6 from '../../assets/images/service-card6.jpg';
import Maskman from '../../assets/images/clientprofilepic1.jpg';
import Maskman1 from '../../assets/images/clientprofilepic2.jpg';
import Maskman3 from '../../assets/images/clientprofilepic3.jpg';

const DealsForYou = () => {
  return (
    <div className={HeroClasses["hero"]} id='our-services'>
      <div className={HeroClasses['exclusive-main']}>
        <h1 className={HeroClasses['exclusive-para']}>Our Services</h1>
      </div>
      <div className="deal-container">
        {/* Deal 1 */}
        <div className="card fast-moving" data-aos="fade-zoom-in"
          data-aos-easing="ease-in-back"
          data-aos-delay="100"
          data-aos-offset="0">
          <div className="title-container">
            <h3 className="title">Maid Services</h3>
          </div>
          <div className="image-container fast-moving-img">
            <img className="image-one" src={bitmap} alt="Fast Moving Deals" />
          </div>
          <div className="link-container">
          </div>
        </div>

        {/* Deal 2 */}
        <div className="card ending-soon" data-aos="fade-zoom-in"
          data-aos-easing="ease-in-back"
          data-aos-delay="200"
          data-aos-offset="0">
          <div className="title-container">
            <h3 className="title">Helper Services</h3>
          </div>
          <div className="image-container ending-soon-img">
            <img className="image-two" src={bitmapsoap} alt="Ending Soon Deals" />
          </div>
          <div className="link-container">
          </div>
        </div>
      </div>

    </div>
  );
};

export default DealsForYou;
