import React from 'react';
import "../maidhelperslider/MaidHelperSlider.css";

const MaidHelperSlider = () => {
    return (
        <ul className='maidhelper-wrapper' id='header'>
            <li>
                <span>
                    <p className="animated-text">Professional Service</p>
                </span>
            </li>
            <li>
                <span>
                    <p className="animated-text">We Clean a lot</p>
                </span>
            </li>
            <li>
                <span>
                    <p className="animated-text">Clean Home</p>
                </span>
            </li>
            <li>
                <span>
                    <p className="animated-text">Fair Price</p>
                </span>
            </li>
            <li>
                <span>
                    <p className="animated-text">We do it well</p>
                </span>
            </li>
            <li>
                <span>
                    <p className="animated-text">Worry no more</p>
                </span>
            </li>
        </ul>
    );
}

export default MaidHelperSlider;
