import React from 'react';
import "../Footer/Footer.css";
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className='footer bg-dark text-white py-6' id='footer'>
            <div className='container'>
                <div className='footer-links'>
                    <div>
                        <a href='#header'>Home</a>
                        <a href='#about-us'>About Us</a>
                        <a href='#our-services'>Our Services</a>
                        <a href='#contact-us'>Contact</a>
                    </div>
                    <div>
                        <Link to='/terms-and-conditions'>Terms & Conditions</Link>
                        <Link to='/privacy-policy'>Privacy and Policy</Link>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
