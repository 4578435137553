import React, { useState } from 'react';
import './ServiceBookingForm.css'; // Optional: for custom styling
import HeroClasses from '../Hero/Hero.module.css';

const ServiceBookingForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
        mobileNumber: ''
    });

    const [showModal, setShowModal] = useState(false); // State for modal visibility

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const payload = {
            name: formData.name,
            email: formData.email,
            mobileNumber: formData.mobileNumber,
            message: formData.message
        };

        try {
            const response = await fetch('https://xapi.theadify.com/page/landingpage', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });

            if (response.status === 201) {
                setShowModal(true); // Show modal on success
            } else {
                throw new Error(`Error: ${response.status}`);
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    const closeModal = () => {
        setShowModal(false); // Hide modal when close button is clicked
        setFormData({
            name: '',
            email: '',
            message: '',
            mobileNumber: ''
        }); // Reset form data
    };

    return (
        <div>
            <div className={HeroClasses['exclusive-main']} id='contact-us'>
                <h1 className={HeroClasses['exclusive-para']}>Contact Us</h1>
            </div>
            <div className='form-main'>
                <div className="form-container">
                    <h2>Service Booking Form</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label className='label-main' htmlFor="fullName">Name:</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label className='label-main' htmlFor="email">Email:</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label className='label-main' htmlFor="mobileNumber">Mobile Number:</label>
                            <input
                                type="text"
                                id="mobileNumber"
                                name="mobileNumber"
                                value={formData.mobileNumber}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label className='label-main' htmlFor="Message">Message:</label>
                            <textarea
                                id="message"
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                            />
                        </div>
                        <button className='service-btn' type="submit">Submit</button>
                    </form>
                </div>
            </div>

            {/* Modal */}
            {showModal && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={closeModal}>&times;</span>
                        <h2>Success!</h2>
                        <p>Your form has been submitted successfully.</p>
                        <button onClick={closeModal}>Close</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ServiceBookingForm;
