import React from 'react'
import './ClientSays.css';
import Maskman4 from '../../assets/images/clientprofilepic4.jpg'
import Maskman5 from '../../assets/images/clientprofilepic5.jpg'
import Maskman6 from '../../assets/images/clientprofilepic7.jpg'


const ClientSays = () => {
    return (
        <div className='client-container'>
            <div className='client-container_left'>
                <h1>What Our Customers Say About Us</h1>
                <p style={{ textAlign: 'justify' }}>At Adify, we are deeply committed to delivering top-notch cleaning services that leave a lasting impression on our clients. Our team’s meticulous attention to detail ensures that every space we clean is transformed into a pristine environment. We believe that a clean, organized space contributes to a better quality of life, both at home and at work. Using eco-friendly products and advanced techniques, we strive for excellence in every job we undertake. Our clients’ satisfaction is our greatest reward, and we are proud to have earned their trust through consistent, high-quality service. Their positive feedback motivates us to keep raising the bar in the cleaning industry. Here’s what some of our happy customers have to say about their experience with Adify:</p>
                <p>We’ve earned their trust with our attention to detail, reliability, and commitment to excellence.</p>
            </div>
            <div className='client-container_right'>
                <div className='client-card' 
                    data-aos="zoom-in">
                    <img style={{height: '60px', objectFit: 'cover'}} src={Maskman4} alt='user'></img>
                    <div className='client_card_content'>
                        <span><i className="ri-double-quotes-l"></i></span>
                        <div className='client_card_details'>
                            <p>Adify transformed my home! Their attention to detail is incredible, and I love how fresh everything feels. Highly recommended!</p>
                            <h4>- Sarah Johnson</h4>
                        </div>
                    </div>
                </div>
                <div className='client-card' 
                    data-aos="zoom-in">
                    <img style={{height: '60px', objectFit: 'cover'}} src={Maskman6} alt='user'></img>
                    <div className='client_card_content'>
                        <span><i className="ri-double-quotes-l"></i></span>
                        <div className='client_card_details'>
                            <p>The Adify team did a fantastic job cleaning our office. They were thorough, professional, and left the place spotless. Will definitely hire them again!</p>
                            <h4>- Michael Smith</h4>
                        </div>
                    </div>
                </div>
                <div className='client-card' 
                    data-aos="zoom-in">
                    <img style={{height: '60px', objectFit: 'cover'}} src={Maskman5} alt='user'></img>
                    <div className='client_card_content'>
                        <span><i className="ri-double-quotes-l"></i></span>
                        <div className='client_card_details'>
                            <p>Adify’s service exceeded my expectations. They took care of every nook and cranny in my home, and their team was so friendly and efficient.</p>
                            <h4>- Emily Davis</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ClientSays
